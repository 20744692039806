<template>
  <div class="page-container">
    <div class="score-container">
      <div class="score">Transparency {{ transparencyScore }}%</div>
      <div class="score">Circularity - {{ circularityRange }}</div>
    </div>

    <v-form ref="valueChainForm" @submit.prevent="submitForm" class="full-form">
      <div class="form-circle-container">
        <value-chain-circle
          :is-edit-mode="true"
          :title="newValueChain.name"
          :radius="180"
          :values="newValueChain.values"
          @update="handleCircleUpdate"
        ></value-chain-circle>
      </div>
      <v-text-field
        ref="proxyField"
        class="proxy-field"
        :rules="rules.circleRule"
        outlined
        dense
      ></v-text-field>
      <v-text-field
        v-model="newValueChain.name"
        label="Value Chain Name"
        :rules="rules.valueChainNameRule"
        background-color="white"
        outlined
        dense
      ></v-text-field>
      <v-select
        v-model="newValueChain.product_type"
        :items="productTypes"
        label="Product Type"
        :rules="rules.productTypeRule"
        background-color="white"
        item-text="name"
        item-value="id"
        outlined
        dense
      ></v-select>
      <v-textarea
        v-model="newValueChain.description"
        label="Description"
        :rules="rules.descriptionRule"
        background-color="white"
        outlined
        dense
      ></v-textarea>

      <div class="form-actions">
        <ui-button class="md-raised" title="Save" @click="submitForm" />
      </div>
    </v-form>
  </div>
</template>

<script>
/* eslint-disable @typescript-eslint/camelcase */
import ValueChainCircle from "@/components/ValueChainCircle.vue";
import restAdapter from "../restAdapter";
import auth from "@/auth";
import {
  calculateTransparencyScore,
  calculateCircularityRange,
} from "./../utils/valueChainHelper";
import _ from "lodash";
import routes from "@/router/routes";
import notification from "@/notification";

export default {
  components: {
    ValueChainCircle,
  },
  data() {
    return {
      newValueChain: {
        name: "",
        product_type: "",
        description: "",
        values: {
          design: null,
          rawMaterial: null,
          spinning: null,
          weaving: null,
          dyeing: null,
          sampling: null,
          trims: null,
          manufacturing: null,
          delivery: null,
          donations: [],
          repairs: [],
          upcycles: [],
          recycles: [],
          resales: [],
        },
      },
      productTypes: [],
      tracesList: [],
      rules: {
        valueChainNameRule: [
          (value) => !!value || "Value chain name is required",
          (value) =>
            (value && value.length <= 40) ||
            "Value chain name must not exceed 40 characters",
        ],
        productTypeRule: [(value) => !!value || "Product type is required"],
        descriptionRule: [
          (value) =>
            !value ||
            (value && value.length <= 1000) ||
            "Description is too long",
        ],
        circleRule: [
          (value) => {
            let somethingFilled = false;
            Object.keys(this.newValueChain.values).forEach((key) => {
              if (
                this.newValueChain.values[key] &&
                (!Array.isArray(this.newValueChain.values[key]) ||
                  this.newValueChain.values[key].length > 0)
              ) {
                somethingFilled = true;
              }
            });
            return somethingFilled || "Value chain is empty";
          },
        ],
      },
    };
  },
  computed: {
    transparencyScore() {
      return calculateTransparencyScore(this.newValueChain.values);
    },
    circularityRange() {
      return _.startCase(calculateCircularityRange(this.newValueChain.values));
    },
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getTracesList();
      this.getProductTypes();
    },
    async submitForm() {
      if (!this.$refs.valueChainForm.validate()) {
        return;
      }

      const payload = this.processPayload();

      restAdapter
        .post(`/api/accounts/${auth.user.account.uuid}/value_chains`, payload)
        .then(() => {
          notification.success("Value chain created successfully");
          this.$router.push({
            name: routes.ACCOUNT_PROFILE,
          });
        });
    },
    processPayload() {
      return {
        name: this.newValueChain.name,
        product_type: this.newValueChain.product_type,
        description: this.newValueChain.description,
        traces: this.processTraces(this.newValueChain.values),
        ...this.processCircularFields(this.newValueChain.values),
      };
    },
    processTraces(valueChain) {
      const traces = [];
      this.tracesList.forEach((trace) => {
        if (valueChain[trace.code]) {
          traces.push({
            trace_id: trace.id,
            supplier_id: valueChain[trace.code]?.id,
          });
        }
      });
      return traces;
    },
    processCircularFields(valueChain) {
      const circularFields = {};
      Object.keys(valueChain).forEach((key) => {
        if (!this.tracesList.find((trace) => trace.code === key)) {
          circularFields[key] = valueChain[key].map((supplier) => ({
            supplier_id: supplier.id,
          }));
        }
      });
      return circularFields;
    },
    getProductTypes() {
      restAdapter.get("/api/inquire_product_types").then((response) => {
        this.productTypes = response.data.productTypes;
      });
    },
    getTracesList() {
      restAdapter.get("/api/traces").then((response) => {
        this.tracesList = response.data.traces.map((trace) => ({
          id: trace.id,
          code: _.camelCase(trace.display_name),
        }));
      });
    },
    handleCircleUpdate(data) {
      this.newValueChain.values = {
        ...this.newValueChain.values,
        ...data,
      };
      this.$refs.proxyField.validate();
    },
  },
};
</script>

<style lang="scss" scoped>
.page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 20px;
  background-color: #4730680f;
  box-sizing: border-box;
}

.score-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
  gap: 30px;
}

.score {
  font-size: 16px;
  font-weight: bold;
  background-color: #c2aae8;
  padding: 10px 20px;
}

.full-form {
  padding: 0px 50px;
  width: 100%;
}

.form-circle-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.proxy-field::v-deep .v-input__slot {
  display: none;
}

.proxy-field::v-deep .v-messages__message {
  text-align: center;
}
</style>
